import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import { TUiIconDirection, TUiIconSize } from '../ui-icon.interface';

enum UiIconGrapeColor {
    green = '#B8E994',
    blue = '#4A69BD'
}

export type TUiIconGrapeColor = keyof typeof UiIconGrapeColor;

@Component({
    selector: 'ui-grape-icon',
    template: ` <mat-icon [svgIcon]="'grape'" [ngStyle]="{ color: colorCode }" /> `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UiGrapeIconComponent {
    @HostBinding('class.ui-icon-grape') baseClassName = true;

    @Input() color: TUiIconGrapeColor | string = 'green';
    @Input() direction!: TUiIconDirection;
    @Input() size!: TUiIconSize;

    get colorCode() {
        return UiIconGrapeColor[this.color];
    }
}
