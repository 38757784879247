<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
    <defs>
        <clipPath [attr.id]="elementId">
            <rect x="3" y="3" width="34" [attr.height]="score + '%'" />
        </clipPath>
    </defs>
    <circle cx="20" cy="20" r="18" style="fill: #393d3b; stroke-width: 4; stroke: white" />
    <circle cx="20" cy="20" r="19" style="fill: transparent; stroke-width: 2; stroke: #393d3b" />
    <circle cx="20" cy="20" r="17" fill="white" [attr.clip-path]="clipPath" />
</svg>
