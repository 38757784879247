import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    Input,
    ViewEncapsulation
} from '@angular/core';

import { IProduct } from '@vb/shared/interfaces';

import { TYPE_ICON_MAP } from './ui-category-icon.config';

@Component({
    selector: 'ui-category-icon',
    template: ` <mat-icon *ngIf="icon" [svgIcon]="icon" /> `,
    styleUrls: ['./ui-category-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class UiCategoryIconComponent {
    @Input() set product(value: IProduct | undefined) {
        this.icon = this.generateTypeIcon(value);
    }

    @HostBinding('class.ui-icon') baseClassName = true;

    icon?: string;

    generateTypeIcon(product?: IProduct): string | undefined {
        if (!product?.category) {
            return undefined;
        }

        const keys = [
            `${product.category.level2}-${product.category.level3}`,
            `${product.category.level2}`
        ];

        const key = keys.find((key) => TYPE_ICON_MAP[key]);

        if (!key) {
            return undefined;
        }

        return TYPE_ICON_MAP[key];
    }
}
