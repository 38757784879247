export const COUNTRY_ISO_MAP: {
    [key: string]: string;
} = {
    argentina: 'ar',
    armenien: 'am',
    australien: 'au',
    barbados: 'bb',
    belgien: 'be',
    bulgarien: 'bg',
    chile: 'cl',
    danmark: 'dk',
    'dominikanska-republiken': 'do',
    finland: 'fi',
    frankrike: 'fr',
    grekland: 'gr',
    irland: 'ie',
    italien: 'it',
    japan: 'jp',
    kanada: 'ca',
    kroatien: 'hr',
    moldavien: 'md',
    mexiko: 'mx',
    nederländerna: 'nl',
    norge: 'no',
    'nya-zeeland': 'nz',
    osterrike: 'at',
    panama: 'pa',
    polen: 'pl',
    portugal: 'pt',
    ryssland: 'ru',
    schweiz: 'ch',
    serbien: 'rs',
    spanien: 'es',
    storbritannien: 'gb',
    sverige: 'se',
    sydafrika: 'za',
    tjeckien: 'cz',
    tyskland: 'de',
    ungern: 'hu',
    ukraina: 'ua',
    usa: 'us',
    uruguay: 'uy',
    venezuela: 've'
};
