export const handelize = (function () {
    const mapping = {} as { [key: string]: string };

    const from = 'åäöÅÄÖÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç\'{}´-+¿?.,;:[]*¨¡!=()&%$#/"';
    const to = 'aaoAAOAAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuunncc                            ';

    for (let i = 0, j = from.length; i < j; i++) mapping[from.charAt(i)] = to.charAt(i);

    return function (str: string): string {
        const ret: string[] = [];

        for (let i = 0, j = str.length; i < j; i++) {
            const c = str.charAt(i);

            if (Object.prototype.hasOwnProperty.call(mapping, str.charAt(i))) {
                ret.push(mapping[c]);
            } else {
                ret.push(c);
            }
        }

        return ret
            .join('')
            .trim()
            .replace(/[^-_A-Za-z0-9]+/g, '-')
            .toLowerCase();
    };
})();
