import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { UiCategoryIconComponent } from './category/ui-category-icon.component';
import { UiFlagIconComponent } from './flag/ui-flag-icon.component';
import { UiGrapeIconComponent } from './grape/ui-grape-icon.component';
import { UiSugarIconComponent } from './sugar/ui-sugar-icon.component';
import { UiTasteIconComponent } from './taste/ui-taste-icon.component';
import { UiIconGroupListComponent } from './ui-icon-group-list.component';
import { UiIconGroupComponent } from './ui-icon-group.component';
import { UiIconComponent } from './ui-icon.component';

@NgModule({
    imports: [CommonModule, MatIconModule],
    declarations: [
        UiIconComponent,
        UiIconGroupComponent,
        UiIconGroupListComponent,
        UiCategoryIconComponent,
        UiFlagIconComponent,
        UiGrapeIconComponent,
        UiSugarIconComponent,
        UiTasteIconComponent
    ],
    exports: [
        UiIconComponent,
        UiIconGroupComponent,
        UiIconGroupListComponent,
        UiCategoryIconComponent,
        UiFlagIconComponent,
        UiGrapeIconComponent,
        UiSugarIconComponent,
        UiTasteIconComponent
    ]
})
export class UiIconsModule {}
