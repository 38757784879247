import { Component, Input } from '@angular/core';

import { UiIconComponentBase } from '../ui-icon-abstract.class';

let idCounter = 0;

@Component({
    selector: 'ui-taste-icon',
    styleUrls: ['./ui-taste-icon.component.scss'],
    templateUrl: './ui-taste-icon.component.html',
    host: {
        class: 'ui-icon__element'
    }
    // hostDirectives: [UiIconElementDirective],
    // providers: [
    //     {
    //         provide: UiIconComponentBase,
    //         useExisting: forwardRef(() => UiTasteIconComponent)
    //     }
    // ]
})
export class UiTasteIconComponent extends UiIconComponentBase {
    private _id = idCounter++;

    @Input()
    get score() {
        return this._score;
    }
    set score(value: number) {
        const score = 100 - value * 10;

        this._score = score;
    }
    private _score = 0;

    get elementId() {
        return `taste-icon-clip-${this._id}`;
    }
    get clipPath() {
        return `url(#${this.elementId})`;
    }
}
