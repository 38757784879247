export const TYPE_ICON_MAP: { [key: string]: string } = {
    //Remove when icon migration is done
    'type-red': 'wine-red',
    'type-white': 'wine-white',
    'type-rose': 'wine-rose',
    'type-sparkling': 'wine-sparkling',
    'cider-blanddrycker': 'cider-cider',
    'type-beer': 'beer-light',
    'type-beer__light': 'beer-light',
    'type-beer__dark': 'beer-dark',
    'type-beer__medium': 'beer-medium',
    'type-rum': 'spirit-dark',
    'type-whiskey': 'spirit-whisky',
    'type-whisky': 'spirit-whisky',
    'type-spirits': 'spirit-white',
    'spirit-white': 'spirit-white',
    'spirit-dark': 'spirit-dark',

    //VIN
    Rött: 'wine-red',
    Vitt: 'wine-white',
    Rosé: 'wine-rose',
    Mousserande: 'wine-sparkling',
    'Mousserande-Rosé': 'wine-sparkling-rose',
    'Smaksatt vin & fruktvin': 'wine-fruit',
    'Glögg och Glühwein': 'wine-dessert-gluhwein',
    Vermouth: 'wine-vermouth',
    Vinlåda: 'wine-crate',
    Sake: 'wine-sake',

    //CIDER - BLANDDRYCK
    Blanddryck: 'cider-mixed',
    Cider: 'cider-cider',

    //ÖL
    Ale: 'beer-ale',
    'Ljus lager': 'beer-light',
    'Mellanmörk & Mörk lager': 'beer-medium',
    'Porter & Stout': 'beer-dark',
    Veteöl: 'beer-light-wheat',

    //SPRIT
    'Akvavit & Kryddat brännvin': 'spirit-white',
    'Armagnac & Brandy': 'spirit-dark',
    Bitter: 'spirit-dark',
    Calvados: 'spirit-dark',
    Cognac: 'spirit-dark',
    'Drinkar & Cocktails': 'spirit-cocktail',
    'Frukt & Druvsprit': 'spirit-white',
    Gin: 'spirit-white',
    'Gin & Genever': 'spirit-white',
    'Grappa & Marc': 'spirit-white',
    Likör: 'spirit-liqueur',
    'Likör-Gräddlikör': 'spirit-liqueur-cream',
    Punsch: 'spirit-punsch',
    Rom: 'spirit-dark',
    'Rom & Lagrad sockerrörssprit': 'spirit-dark',
    'Smaksatt sprit': 'spirit-white',
    'Tequila & Mezcal': 'spirit-white',
    'Vodka & Okryddat brännvin': 'spirit-white',
    Whiskey: 'spirit-whisky',
    Whisky: 'spirit-whisky'
};
