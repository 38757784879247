import { ISymbol } from './ui-pairing-icon.interface';

export const PAIRING_SYMBOL_MAP: { [key: string]: ISymbol } = {
    Grönsaker: {
        iconName: 'taste-gronsaker',
        displayName: 'Sallader'
    },
    Fläsk: {
        iconName: 'taste-flask',
        displayName: 'Fläsk'
    },
    Dessert: {
        iconName: 'taste-dessert',
        displayName: 'Dessert'
    },
    Avec: {
        iconName: 'taste-avec',
        displayName: 'Avec'
    },
    'Avec/digestif': {
        iconName: 'taste-avec',
        displayName: 'Avec'
    },
    Aperitif: {
        iconName: 'taste-aperitif',
        displayName: 'Aperitif'
    },
    Vilt: {
        iconName: 'taste-vilt',
        displayName: 'Vilt'
    },
    Skaldjur: {
        iconName: 'taste-skaldjur',
        displayName: 'Skaldjur'
    },
    Sällskapsdryck: {
        iconName: 'taste-sallskapsdryck',
        displayName: 'Mingel'
    },
    Ost: {
        iconName: 'taste-ost',
        displayName: 'Ost'
    },
    Nöt: {
        iconName: 'taste-not',
        displayName: 'Nöt'
    },
    Lamm: {
        iconName: 'taste-lamm',
        displayName: 'Lamm'
    },
    Kryddstark: {
        iconName: 'taste-kryddstark',
        displayName: 'Kryddstark'
    },
    Kryddstarkt: {
        iconName: 'taste-kryddstark',
        displayName: 'Kryddstark'
    },
    Fisk: {
        iconName: 'taste-fisk',
        displayName: 'Fisk'
    },
    Fågel: {
        iconName: 'taste-fagel',
        displayName: 'Fågel'
    },
    Buffémat: {
        iconName: 'taste-buffemat',
        displayName: 'Buffémat'
    },
    Asiatiskt: {
        iconName: 'taste-asiatiskt',
        displayName: 'Asiatiskt'
    }
};
