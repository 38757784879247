import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    Input,
    ViewEncapsulation
} from '@angular/core';

import { UiIconElementDirective } from '../ui-icon-element.directive';

@Component({
    selector: 'ui-sugar-icon',
    template: ` <p>{{ value }}g</p> `,
    styleUrls: ['./ui-sugar-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    hostDirectives: [UiIconElementDirective]
})
export class UiSugarIconComponent {
    @Input() value: number | string | undefined;

    @HostBinding('class.ui-sugar-icon') baseClassName = true;
    @HostBinding('class.ui-icon') parentClassName = true;
}
