import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { handelize } from '@vb/utilities/helpers/handelize';

import { COUNTRY_ISO_MAP } from './flag-country-map.constant';

@Component({
    selector: 'ui-flag-icon',
    templateUrl: './ui-flag-icon.component.html',
    styleUrls: ['./ui-flag-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'ui-flag-icon'
    }
})
export class UiFlagIconComponent {
    className!: string | undefined;

    @Input()
    get country() {
        return this._country;
    }
    set country(value: string | undefined) {
        if (!value || value === this._country) {
            return;
        }

        this._country = handelize(value);
        this.className = this._generateIconClassName(this._country);
    }
    private _country!: string | undefined;

    private _generateIconClassName(value: string | undefined) {
        if (!value) {
            return 'unknown';
        }
        if (value.length === 2) {
            return `fi-${this._country}`;
        }

        const mappedIsoCode = COUNTRY_ISO_MAP[value];

        return `fi-${mappedIsoCode}`;
    }
}
